import { Platform, Text, Image } from 'react-native';
import { Icon, Theme, Switch } from 'ui-m/rn';
import icons from './config/icons';

const autoScrollInputIntoView = (() => {
  let enabled = false;
  return () => {
    if (!enabled) {
      enabled = true;
      document.body.addEventListener("focusin", () => {
        const activeElement = document.activeElement;
        if (
          activeElement.nodeName === "INPUT" ||
          activeElement.nodeName === "TEXTAREA"
        ) {
          setTimeout(() => {
            activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 300);
        }
      });
    }
  };
})();

/* This scripe make the body full-height normally out of APP WebView */
if (Platform.OS === 'web') {
  Theme.set({
    isWebInAPP: /(\?|&)(mcKey|key|loginToken)=/.test(window.location.search),
  });
}

// 非手机屏幕（Pad、PC）的网页版，默允许复制文字内容
if (!Theme.isPhoneSizedWindow && Platform.OS === 'web') {
  if (Text.defaultProps == null) {
    Text.defaultProps = {};
  }
  Text.defaultProps.selectable = true;
}

Icon.registerBuiltInIcons(icons);

if (Platform.OS === "web") {
  const style = document.createElement("style");
  style.innerHTML = `.pswp__top-bar { top: ${Theme.statusBarHeight}px }`;
  document.head.appendChild(style);
}

if (Image.defaultProps === null || Image.defaultProps === undefined) {
  Image.defaultProps = {};
}
if (typeof Image.defaultProps === "object") {
  Image.defaultProps.pointerEvents = "none";
}

export const loadTheme = async (type) => {
  if (Theme.isWebInAPP) {
    autoScrollInputIntoView();
  }

  // Switch handler 的颜色
  Switch.defaultProps.activeThumbColor = "#fff";

  if (type === "MS") {
    await import("ui-m/rn/themes/microStoreManager");
    return;
  }
  if (type === "NANO") {
    await import("ui-m/rn/themes/mshop");
    // Nanoshop也用微店铺Menu样式
    Theme.menuItemTitleColor = '#201B2C';
    Theme.menuItemFontSize = 14;
    Theme.menuColor = '#FFF';
    Theme.menuShadowColor = 'rgba(0,0,0,0.2)';
    Theme.menuItemPaddingLeft = 16;
    Theme.menuItemPaddingRight = 28;
    Theme.menuItemPaddingTop = 12;
    Theme.menuItemSeparatorColor = '#FFF';
    Theme.menuItemIconPaddingRight = 12;
    return;
  }

  // 黑蓝端
  await import("ui-m/rn/themes/mc");
  Switch.defaultProps.activeColor = '#64c367';
};
