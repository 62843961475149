import React from "react";
import { StyleSheet, Image, Text } from "react-native";
import { Teaset, Icon } from "ui-m/rn";
import { createMenuShadow } from "@utils/other";
import TouchableWithPosition from "./TouchableWithPosition";
const { Overlay } = Teaset;

const show = (options = {}, overlayOptions = {}) => {
  const {
    position,
    items = [],
    popoverProps = {},
    showSelectedPlaceholder,
  } = options;

  const overlayId = Overlay.show((
    <Overlay.PopoverView
      fromBounds={position}
      showArrow={false}
      shadow={true}
      align="center"
      overlayPointerEvents="none"
      {...popoverProps}
      popoverStyle={[
        { transform: [{ translateY: 5 }] },
        createMenuShadow(),
        popoverProps.popoverStyle,
      ]}
    >
      {
        items.filter(i => i && typeof i === "object").map((i, ii) => {
          const {
            text, textStyle,
            left, logo,
            right, selected,
            disabled, onPress, onPressDisabled,
          } = i;

          let leftContent, rightContent;
          if (React.isValidElement(left)) {
            leftContent = left;
          } else if (logo) {
            leftContent = (<Image source={logo} style={styles.logo} />);
          }

          if (React.isValidElement(right)) {
            rightContent = right;
          } else if (selected) {
            rightContent = (<Icon icon="cellCheck" />);
          } else if (showSelectedPlaceholder) {
            rightContent = (<Icon icon="cellCheck" style={{ opacity: 0 }} />);
          }

          const _textStyle = {
            marginLeft: leftContent ? 8 : 0,
            marginRight: rightContent ? 10 : 0,
          };

          return (
            <TouchableWithPosition
              key={`${ii}`}
              style={[styles.item, { width: position.width, opacity: disabled ? 0.6 : 1 }]}
              disabled={disabled}
              onPress={() => {
                Overlay.hide(overlayId);
                typeof onPress === "function" && onPress();
              }}
              onPressDisabled={() => {
                // 禁用 && 能点
                if (typeof onPressDisabled === "function") {
                  Overlay.hide(overlayId);
                  onPressDisabled();
                }
              }}
            >
              {leftContent}
              <Text style={[styles.text, _textStyle, textStyle]} >{text}</Text>
              {rightContent}
            </TouchableWithPosition>
          );
        })
      }
    </Overlay.PopoverView>
  ), { hideWhenUnderViewMove: true, ...overlayOptions });
};

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 8,
    minHeight: 44,
  },
  logo: {
    width: 22,
    height: 22,
  },
  text: {
    flex: 1,
    color: '#221503',
    fontSize: 15,
    lineHeight: 18,
    fontWeight: '600',
  },
});

export default {
  show,
};
