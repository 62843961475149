import { getMcKey } from "./globalUtils";
import * as MCSDK from "./mcAppJsSDK";
import { isTestEnv } from "./other";
import qs from "qs";
import _ from "lodash";

// nativeInfo: {
//   name: MC | MCS | MS | NANO | WEB
//   auth: MC | MS | NANO
//   theme: MC | MCS | MS | NANO
//   device
//   deviceId
//   osVersion
//   appVersion
//   region
//   lang
//   companyId
//   staffId
// };

const reactNativeMessageResolveMap = {};

window._onReactNativeMessage = (id, content) => {
  if (reactNativeMessageResolveMap[id]) {
    reactNativeMessageResolveMap[id](content);
  }
};

window._postReactNativeMessage = content => new Promise(resolve => {
  const id = Date.now() + `${Math.random()}`.slice(2);
  reactNativeMessageResolveMap[id] = resolve;
  window.ReactNativeWebView?.postMessage?.(JSON.stringify({ id, content }));
});

export const callRNNativeAsyncFunc = async (name, data) => {
  const res = await window._postReactNativeMessage({ name, data });
  name !== "log" && callNativeLog(res); // 调试用
  return res;
};

const ensureViladObject = i => (i && typeof i === "object") ? i : {};

export const getNativeInfo = (() => {
  let nativeInfo;

  return () => {
    if (!nativeInfo) {
      // 测试用
      if (isTestEnv()) {
        const urlParams = qs.parse(window.location.search.replace("?", ""));
        if (["MC", "MCS", "MS", "NANO"].includes(urlParams.native)) {
          nativeInfo = Promise.resolve({
            name: urlParams.native,
            ..._.pick(urlParams, [
              "device",
              "deviceId",
              "osVersion",
              "appVersion",
              "region",
              "lang",
              "companyId",
              "staffId",
            ]),
          });
          return nativeInfo;
        }

      }
      nativeInfo = new Promise(async resolve => {
        if (/(\?|&)(mcKey|key)=/.test(window.location.search)) {
          setTimeout(() => resolve({ name: "WEB" }), 3000); // 超时就算浏览器
          // 黑端、蓝端
          const mcAppInfo = await MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.SYSTEM.USER_DATA);
          if (mcAppInfo) {
            try { mcAppInfo.mobile_info = JSON.parse(mcAppInfo.mobile_info); } catch (error) { }
            try { mcAppInfo.shops = JSON.parse(mcAppInfo.shops); } catch (error) { }
          }
          resolve({
            ...ensureViladObject(mcAppInfo),
            name: (+mcAppInfo.app_pid === 62 || +mcAppInfo.app_pid === 92) ? "MCS" : "MC", // 61:黑端ios | 62:蓝端ios | 91:黑端android | 92:蓝端android
            device: mcAppInfo?.mobile_info?.modelName,
            deviceId: mcAppInfo?.device_sn,
            osVersion: mcAppInfo?.mobile_info?.systemVersion,
            appVersion: mcAppInfo?.app_version,
            region: mcAppInfo?.system_region,
            lang: mcAppInfo?.language_key,
            companyId: mcAppInfo?.company_id,
            staffId: mcAppInfo?.staff_id,
          });
        } else if (/(\?|&)(loginToken)=/.test(window.location.search)) {
          // 紫端、Nanoshop
          const info = await callRNNativeAsyncFunc("getNativeInfo");
          callRNNativeAsyncFunc("log", info);
          resolve(ensureViladObject(info));
        } else {
          resolve({ name: "WEB" });
        }
      });
    }

    return nativeInfo;
  };
})();

export const callNativeLog = log => getNativeInfo().then(info => {
  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("log", log);
  }
});

export const callNativeClose = () => getNativeInfo().then(info => {
  if (["MC", "MCS"].includes(info.name)) {
    return MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.SYSTEM.CLOSE);
  }
  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("close");
  }
});

export const callNativeOpenURL = (url) => getNativeInfo().then(info => {
  if (["MC", "MCS"].includes(info.name)) {
    return MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.NAVIGATION.GENERAL, {
      "route": "/system_browser",
      "params": { host: url },
    });
  }
  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("openURL", url);
  }
  if (info.name === "WEB") {
    window.open(url);
  }
});

export const callMCCreateGoods = mcTempGoodsCode => MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.NAVIGATION.GENERAL, {
  "route": "/create_module",
  "params": { "key": "product_air_into", "present": "1", "id": mcTempGoodsCode },
});

// notice: docId其实是发货记录ID
export const callMCSEditShippingRecord = (shippingRecordId) => MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.NAVIGATION.GENERAL, {
  "route": "/part_ship_view",
  "params": { docId: `${shippingRecordId}` },
});

export const callMSInputTrackingNumber = (options) => callRNNativeAsyncFunc("inputTrackingNumber", options);

export const callNativeLocalSetting = (key) => getNativeInfo().then(async info => {
  if (["MC", "MCS"].includes(info.name)) {
    if (key === "printParcelLabelAllowInputNum") {
      return +(await MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.SYSTEM.GET_DATA_CENTER_INFO, { route: "allow_logistic_num" })) === 1;
    }
    if (key === "mcKey") {
      return getMcKey();
    }
  }

  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("getDataCetnerKey", key);
  }
});

export const callNativeSelectDate = (options) => getNativeInfo().then(async info => {
  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("selectDate", options);
  }
});

export const callNativeScanCode = (options) => getNativeInfo().then(async info => {
  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("scanCode", options);
  }
});

export const callNANOMarkOrderAsShipped = (options) => callRNNativeAsyncFunc("markOrderAsShipped", options);

export const callNANOEditOrderSenderAddress = (options) => callRNNativeAsyncFunc("editOrderSenderAddress", options);

export const callNANOEditOrderReceiverAddress = (options) => callRNNativeAsyncFunc("editOrderReceiverAddress", options);
