import AppEvent from "./appEvent";
import { navigate } from "./navigation";
import { ensureValidObject } from ".";

const selectCountry = options => new Promise(async resolve => {
  navigate("SelectCountry", {
    ...ensureValidObject(options),
    commission: AppEvent.Commission({
      callback: resolve,
    }),
  });
});

// TODO: 多选

export {
  selectCountry,
};
