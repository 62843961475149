import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { CellGroup, Teaset, Cell, Space } from 'ui-m/rn';
import _ from 'lodash';
import { delayRun } from '@utils/index';
const { Theme, Drawer } = Teaset;

/*
  DrawerSelector: {
    items: item[]
    value: any // 初始值
  }
  item: {
    title: Cell.title
    subtitle: Cell.subtitle
    footer: Cell.footer

    // 以下参数若未指定，则会尝试从DrawerSelector读取对应值
    selected: ({ value, item, items }) => any
    disabled: ({ value, item, items }) => any
    onPress: async ({ value, setValue, item, items }) => any // 真值 => 不关闭抽屉, 反之亦然
    onPressDisabled: async ({ value, setValue, item, items }) => any // 同onPress
  }
 */
const DrawerSelector = props => {
  const { items, close } = props;
  const [value, setValue] = useState(props.value);

  return (
    <CellGroup>
      {
        items.map((item, ii) => {
          const {
            selected = props.selected,
            disabled = props.disabled,
            onPress = props.onPress,
            onPressDisabled = props.onPressDisabled,
          } = item;

          const isSelected = !!(
            typeof selected === "function"
              ? selected({ value, item, items })
              : selected
          );

          const isDisabled = !!(
            typeof disabled === "function"
              ? disabled({ value, item, items })
              : disabled
          );

          const withDelayClose = fn => typeof fn !== "function" ? fn : async () => {
            // 默认关闭抽屉, 返回真值则不关闭
            (!await fn({ value, setValue, item, items })) && delayRun(close);
          };

          return (
            <Cell
              key={`${ii}`}
              formType="select"
              value={isSelected}
              disabled={isDisabled}
              onPress={isDisabled ? undefined : withDelayClose(onPress)}
              onPressDisabled={isDisabled ? withDelayClose(onPressDisabled) : undefined}
              underlineStyle={{ marginHorizontal: -20 }}
              style={{ opacity: isDisabled ? 0.5 : 1 }}
              {..._.pick(item, ["title", "subtitle", "footer"])}
            />
          );
        })
      }
    </CellGroup>
  );
};

const show = (options) => {
  const {
    maxHeight = 400,
    onCancel,
    onCloseRequest,
    ...props
  } = options;

  const drawer = Drawer.open((
    <ScrollView
      style={{
        maxHeight,
        backgroundColor: "#fff",
      }}
    >
      <DrawerSelector
        {...props}
        close={() => drawer.close()}
      />
      {Theme.isIPhoneX && <Space height={32} />}
    </ScrollView>
  ), "bottom", "none", {
    onCloseRequest: onCloseRequest || (typeof onCancel !== "function" ? undefined : () => {
      drawer?.close?.();
      onCancel();
    }),
  });

  return drawer;
};

DrawerSelector.show = show;

export default DrawerSelector;
