import _ from 'lodash';
import * as countryServices from '../../services/country';
import RNLocalize from 'react-native-localize';
import { createAction, createIdempotentEffect } from '../../utils';

export default {
  namespace: 'country',
  state: {
    list: [],
    deviceLocaleCountry: {},
    isListLoaded: false,
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    advanceSingle(state, { payload }) {
      let advanceList = [...state.list];
      if (payload.regionCode) {
        advanceList = [
          _.find(advanceList, c => c.regionCode === payload.regionCode),
          ..._.remove(advanceList, c => c.regionCode !== payload.regionCode),
        ];
      }
      return { ...state, list: advanceList };
    },
  },
  effects: {
    *ensure({ payload }, { call, put, select }) {
      const { isListLoaded } = yield select(state => state.country);
      if (!isListLoaded) {
        yield yield put({ type: "getList" });
        return yield yield put({ type: "ensure" });
      }
    },
    getList: createIdempotentEffect(function* ({ payload }, { call, put, select }) {
      const res = yield call(countryServices.getList, payload);

      if (res.success) {
        const countryList = res.data;
        const deviceCountryCode = RNLocalize.getCountry();
        const deviceLocaleCountry = countryList.find(c => c.countryCode === deviceCountryCode) || countryList[0];

        yield put(createAction('setState')({
          isListLoaded: true,
          list: countryList,
          deviceLocaleCountry,
        }));
      }

      return res;
    }),
  },
};
