import qs from 'qs';
import { getMcKey, getLoginToken } from '@globalUtils';
import { envConf, appConf } from '../config';
import { select } from './store';
import packageJSON from '../../package.json';
import { getNativeInfo } from './callNative';

let _localTimeOffsetToServerTime = 0; // = serverTime - localTime

// 获取服务器时间
function getServerTime(){
  // = localTime + (serverTime - localTime)
  return Date.now() + _localTimeOffsetToServerTime;
}

// 用每次请求来校准服务端时间
async function _updateServerTimeFromRequestRes(response){
  let offsetTime;
  try {
    const dateStr = response?.headers?.get('date');
    if (dateStr) {
      offsetTime = new Date(dateStr).getTime() - Date.now();
    }
  } catch (error) {
    console.warn(error);
  }
  if (offsetTime !== undefined) {
    _localTimeOffsetToServerTime = offsetTime;
  }
}

// 生成一个短的随机ID，用于识别某个请求
function s4 () {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}


// /**
//  * Requests a URL, returning a promise.
//  *
//  * @param  {string} url       The URL we want to request
//  * @param  {object} [options] The options we want to pass to "fetch"
//  * @return {object}           An object containing either "data" or "err"
//  */
//
// return {
//   success: boolean,
//   data: core data json,
//   status: number,
//   errorMessage: string,
//   // more...
//   errorType: enums, // TODO network_error, ...
//   errorItems: string,
//   errorCode: number,
//   errorDebugInfo: number,
// }

async function request(initialUrl, initialOptions, conf = {}) {
  const nativeInfo = await getNativeInfo();
  const apiBase = nativeInfo.name === "NANO" ? appConf.nanoApiBase : appConf.apiBase;
  let url = initialUrl.indexOf('http') === 0 ? initialUrl : `${apiBase}${initialUrl}`;
  const options = arguments.length > 1 && initialOptions !== undefined ? initialOptions : {};
  const defaultOptions = {
    method: 'GET',
  };
  const newOptions = Object.assign({}, defaultOptions, options);
  newOptions.method = (newOptions.method || '').toUpperCase();

  // body 别名 data
  if (newOptions.data) {
    newOptions.body = newOptions.data;
    delete newOptions.data;
  }
  // 剔除 undefined 的参数
  if (newOptions.body) {
    Object.keys(newOptions.body).forEach((key)=>{
      if (newOptions.body[key] === undefined) {
        delete newOptions.body[key];
      }
    });
  }
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'PATCH' || (newOptions.method === 'DELETE' && conf.bodyWithData)) {
    newOptions.headers = Object.assign({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    }, newOptions.headers);
    newOptions.body = JSON.stringify(newOptions.body);
  } else if (newOptions.body) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + qs.stringify(newOptions.body);
    delete newOptions.body;
  }

  // 统一追加 mcKey 或 loginToken
  if ( !(/[?&]mcKey=/).test(url) ) {
    const mcKey = getMcKey();
    if ( mcKey ) {url += `${url.indexOf('?') === -1 ? '?' : '&'}mcKey=${mcKey}`;}
  }
  if ( !(/[?&]loginToken=/).test(url) ) {
    const loginToken = getLoginToken();
    if ( loginToken ) {url += `${url.indexOf('?') === -1 ? '?' : '&'}loginToken=${loginToken}`;}
  }

  // 统一追加 lang
  if ( !(/[?&]lang=/).test(url) ) {
    const currentLang = (await select(({i18n})=>i18n)).currentLang || '';
    if ( currentLang ) {url += `${url.indexOf('?') === -1 ? '?' : '&'}lang=${currentLang}&defaultLang=en`;}
  }
  if ( !(/[?&]defaultLang=/).test(url) ) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}defaultLang=en`;
  }
  // 统一追加 timeZoneOffset
  if ( !(/[?&]timeZoneOffset=/).test(url) ) {
    const timeZone = await select(({app})=>app.timeZone);
    if ( timeZone ) {url += `${url.indexOf('?') === -1 ? '?' : '&'}timeZoneOffset=${encodeURIComponent(timeZone)}`;}
  }
  // // 统一追加 _cid 识别码
  // if ( !(/[?&]_cid=/).test(url) ) {
  //   url += `${url.indexOf('?') === -1 ? '?' : '&'}_cid=${s4()}`;
  // }
  // 统一追加app信息
  if ( !(/[?&]_ls=/).test(url) && !(/[?&]_v=/).test(url) ) {
    const { loginSource } = appConf.get();
    url += `${url.indexOf('?') === -1 ? '?' : '&'}_ls=${nativeInfo._ls || loginSource}&_v=${nativeInfo._v || packageJSON.version}`;
  }

  if (options.debug) {
    console.log(`[Request] ${  newOptions.method  }: ${  url}`);
    if (newOptions.body) {
      console.log(`[ - Data] ${  newOptions.body}`);
    }
  }

  const _request = {
    url,
    ...newOptions,
  };

  const requestPromise = new Promise(((resolve) => {
    const cache = {
      status: undefined,
      rawResponse: undefined,
    };
    fetch(url, newOptions).then((response) => {
      // 校准客户端与服务端时间差
      _updateServerTimeFromRequestRes(response);
      cache.status = response.status;
      cache.rawResponse = response;
      return response.text();
    }, (e) => {
      resolve({
        success: false,
        data: null,
        errorType: 'network_error',
        errorMessage: null,
        errorItems: null,
        errorCode: null,
        errorInfo: null,
        errorDebugInfo: e,
        status: cache.status,
        response: cache.rawResponse,
        request: _request,
      });
    }).then((responseText) => {
      let res = responseText;
      if (newOptions.json !== false) {
        if (cache.status !== 204 && res !== '') {
          try {
            res = JSON.parse(responseText);
          } catch (e) {
            return resolve({
              success: false,
              data: responseText,
              errorType: 'json_parse_error',
              errorMessage: '',
              errorItems: null,
              errorCode: null,
              errorInfo: null,
              errorDebugInfo: e,
              status: cache.status,
              response: cache.rawResponse,
              request: _request,
            });
          }
        }
      }

      if ((!res || cache.status < 200 || cache.status > 299) && cache.status !== 204 || res.errorCode) {
        resolve({
          success: false,
          data: res?.data || null,
          errorType: res ? 'biz_error' : 'no_content',
          errorMessage: res && res.errorMessage,
          errorItems: res && res.errorItems,
          errorCode: res && res.errorCode,
          errorInfo: res && res.errorInfo,
          errorDebugInfo: res && res.errorDebugInfo,
          status: cache.status,
          response: cache.rawResponse,
          request: _request,
        });
      } else {
        resolve({
          success: true,
          data: res,
          errorType: null,
          errorMessage: '',
          errorItems: null,
          errorCode: null,
          errorInfo: null,
          errorDebugInfo: null,
          status: cache.status,
          response: cache.rawResponse,
          request: _request,
        });
      }
    });
  }));

  let timeout;
  let timeoutPromise = new Promise(resolve => {
    timeout = setTimeout(()=>{
      resolve({
        success: false,
        data: null,
        errorType: 'timeout_error',
        errorMessage: null,
        errorItems: null,
        errorCode: null,
        errorInfo: null,
        errorDebugInfo: null,
        status: null,
        response: null,
        request: _request,
      });
    },15000);
  });

  return Promise.race([requestPromise, timeoutPromise]).then((res)=>{
    clearTimeout(timeout);
    timeoutPromise = null;
    return res;
  });
}

request.getServerTime = getServerTime;

export default request;
