import React, { useRef } from 'react';
import { View } from 'react-native';
import { Input } from "ui-m/rn";


const InputWithPosition = props => {
  const ref = useRef();
  const { onPress } = props;

  return (
    <View ref={ref} collapsable={false}>
      <Input
        {...props}
        onPress={(
          typeof onPress === "function"
            ? () => ref.current.measureInWindow((x, y, width, height) => onPress({ x, y, width, height }))
            : onPress
        )}
      />
    </View>
  );
};

export default InputWithPosition;
