import React, { useEffect } from "react";
import { Input } from "ui-m/rn";
import { getCountryImageUrl } from "@utils/image";
import NetImage from "./NetImage";
import { selectCountry } from "@utils/country";
import { useSelector, useDispatch } from "react-redux";

const useSelectorCountry = param => {
  const { list } = useSelector(state => state.country);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "country/ensure" });
  }, [dispatch]);

  if (!param || !list) {
    return;
  }

  let isoCode, regionCode, name;

  if (typeof param === "object") {
    name = (
      param.name ||
      param.countryName ||
      param.displayName
    );
    isoCode = param.isoCode;
    regionCode = param.regionCode;
  }
  if (typeof param === "string") {
    isoCode = param;
    regionCode = param;
    name = param;
  }


  return list.find(i => (
    i.isoCode === isoCode ||
    i.regionCode === regionCode ||
    i.name === name
  ));
};

const CountryInput = props => {
  const { value, showNationalFlag = true, onChange } = props;

  const country = useSelectorCountry(value);
  const name = country?.name;
  const isoCode = country?.isoCode;

  return (
    <Input
      {...props}
      pointerEvents="none"
      type="select"
      value={name}
      left={!!(showNationalFlag && isoCode) && (
        <NetImage
          url={getCountryImageUrl(isoCode)}
          style={{
            width: 22,
            height: 22,
            borderRadius: 11,
          }}
          resizeMode="contain"
        />
      )}
      onPress={async () => {
        const _country = await selectCountry({ selected: isoCode });
        if (_country && _country.isoCode !== isoCode) {
          onChange(typeof value === "string" ? _country.isoCode : _country);
        }
      }}
      showArrow
    />
  );
};

export default CountryInput;
