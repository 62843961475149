import React from 'react';
import { BackHandler } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { ScreenLoading } from '@components';
import { navigationRef, _setNavigationReady, _setLastRootState, _setLastCurrentRoute } from './utils/navigation';
import { connect } from 'react-redux';
import * as daService from './services/dataAnalyses';
import { lazyWithPreload } from "react-lazy-with-preload";
import SplashScreen from "./containers/SplashScreen";
import StackNavigator from './StackNavigator';
import { callNativeClose } from '@utils/callNative';

const screenComponentMap = {
  // 开发测试
  FrameworkStates: lazyWithPreload(() => import("./containers/DevTools/FrameworkStates")),
  DevTools: lazyWithPreload(() => import("./containers/DevTools")),
  IntentionLauncher: lazyWithPreload(() => import("./containers/IntentionLauncher")),
  ComponentPlayground: lazyWithPreload(() => import("./containers/ComponentPlayground")),
  // 上下游
  SupplyChain: lazyWithPreload(() => import("./containers/SupplyChain")),
  DashboardViewer: lazyWithPreload(() => import("./containers/SupplyChain/DashboardViewer")),
  SelectSupplier: lazyWithPreload(() => import("./containers/SupplyChain/SelectSupplier")),
  // 报表
  DashboardLogin: lazyWithPreload(() => import("./containers/DashboardLogin")),
  DashboardReports: lazyWithPreload(() => import("./containers/DashboardReports")),
  // 导入商品
  ImportProductFromMS: lazyWithPreload(() => import("./containers/ImportProductFromMS")),
  ImportProductPreference: lazyWithPreload(() => import("./containers/ImportProductFromMS/Preference")),
  // 销售渠道
  SalesChannelList: lazyWithPreload(() => import("./containers/SalesChannel")),
  SalesChannelEfashion: lazyWithPreload(() => import("./containers/SalesChannel/Efashion")),
  // 配送服务商
  ShippingServiceProviderList: lazyWithPreload(() => import("containers/ShippingServiceProvider")),
  ShippingServiceProviderSelectShippingMethods: lazyWithPreload(() => import("containers/ShippingServiceProvider/SelectShippingMethods")),
  ShippingServiceProviderSetting37Express: lazyWithPreload(() => import("containers/ShippingServiceProvider/Setting37Express")),
  ShippingServiceProviderConnect37Express: lazyWithPreload(() => import("containers/ShippingServiceProvider/Connect37Express")),
  ShippingServiceProviderGuideAramex: lazyWithPreload(() => import("containers/ShippingServiceProvider/GuideAramex")),
  ShippingServiceProviderConnectAramex: lazyWithPreload(() => import("containers/ShippingServiceProvider/ConnectAramex")),
  ShippingServiceProviderSettingAramex: lazyWithPreload(() => import("containers/ShippingServiceProvider/SettingAramex")),
  // 发货记录
  ShippingRecord: lazyWithPreload(() => import("containers/ShippingRecord")),
  // 创建电子运单
  CreateShippingLabel: lazyWithPreload(() => import("containers/CreateShippingLabel")),
  ShippingLabelParcel: lazyWithPreload(() => import("containers/CreateShippingLabel/Parcel")),
  ShippingLabelSelectShippingMethod: lazyWithPreload(() => import("containers/CreateShippingLabel/SelectShippingMethod")),
  // 国家选择页
  SelectCountry: lazyWithPreload(() => import("containers/SelectCountry")),
};

/*
  页面
  {
    modal: boolean
    name: string
    component: Component

    ...Stack.Screen Props
          options: Screen options
  }

  页面组
  {
    modal: boolean
    name: string
    screens: 页面[]

    ...Stack.Navigator Props
          id
          initialRouteName
          screenOptions
          detachInactiveScreens
  }
 */
const rootScreens = [
  // 独立页面
  { name: "SplashScreen", component: SplashScreen },
  { name: "DashboardLogin", component: screenComponentMap.DashboardLogin },
  { name: "DashboardReports", component: screenComponentMap.DashboardReports },
  { name: "SelectCountry", component: screenComponentMap.SelectCountry, modal: true },
  { name: "ShippingRecord", component: screenComponentMap.ShippingRecord },

  { name: "FrameworkStates", component: screenComponentMap.FrameworkStates },
  { name: "DevTools", component: screenComponentMap.DevTools },
  { name: "IntentionLauncher", component: screenComponentMap.IntentionLauncher },
  { name: "ComponentPlayground", component: screenComponentMap.ComponentPlayground, modal: true },

  // 页面组
  {
    name: "SupplyChainStack",
    screens: [
      { name: "SupplyChain", component: screenComponentMap.SupplyChain },
      { name: "SelectSupplier", component: screenComponentMap.SelectSupplier, modal: true },
      { name: "DashboardViewer", component: screenComponentMap.DashboardViewer },
    ],
  },
  {
    name: "ImportProductStack",
    screens: [
      { name: "ImportProductFromMS", component: screenComponentMap.ImportProductFromMS },
      { name: "ImportProductPreference", component: screenComponentMap.ImportProductPreference },
    ],
  },
  {
    name: "SalesChannelStack",
    screens: [
      { name: "SalesChannelList", component: screenComponentMap.SalesChannelList },
      { name: "SalesChannelEfashion", component: screenComponentMap.SalesChannelEfashion },
    ],
  },
  {
    name: "ShippingServiceProviderStack",
    screens: [
      { name: "ShippingServiceProviderList", component: screenComponentMap.ShippingServiceProviderList },
      { name: "ShippingServiceProviderSelectShippingMethods", component: screenComponentMap.ShippingServiceProviderSelectShippingMethods },
      { name: "ShippingServiceProviderSetting37Express", component: screenComponentMap.ShippingServiceProviderSetting37Express },
      { name: "ShippingServiceProviderConnect37Express", component: screenComponentMap.ShippingServiceProviderConnect37Express },
      { name: "ShippingServiceProviderGuideAramex", component: screenComponentMap.ShippingServiceProviderGuideAramex, modal: true },
      { name: "ShippingServiceProviderConnectAramex", component: screenComponentMap.ShippingServiceProviderConnectAramex, modal: true },
      { name: "ShippingServiceProviderSettingAramex", component: screenComponentMap.ShippingServiceProviderSettingAramex },
    ],
  },
  {
    name: "ShippingLabelStack",
    screens: [
      { name: "CreateShippingLabel", component: screenComponentMap.CreateShippingLabel },
      { name: "ShippingLabelSelectShippingMethod", component: screenComponentMap.ShippingLabelSelectShippingMethod },
      { name: "ShippingLabelParcel", component: screenComponentMap.ShippingLabelParcel },
    ],
  },
];
const rootScreenStacks = rootScreens.filter(i => i.screens);

export const findFirstRootStackByScreenIfNotRootScreen = screenName => {
  if (rootScreens.filter(i => !i.screens).find(i => i.name === screenName)) {
    return;
  }
  return rootScreenStacks.find(stack => stack.screens.find(screen => screen.name === screenName));
};

export const findBestMatchStackByScreen = screenName => {
  // 如果routes里存在配置了这个screen的stack，则优先用。(越迟进入的route，优先级越高)
  const rootState = navigationRef?.current?.getRootState();
  if (rootState?.routes) {
    for (let i = rootState.routes.length - 1; i >= 0; i--) {
      const route = rootState.routes[i];
      const rootStack = rootScreenStacks.find(stack => stack.name === route.name);
      if (rootStack && rootStack.screens.find(screen => screen.name === screenName)) {
        return rootStack;
      }
    }
  }
  return findFirstRootStackByScreenIfNotRootScreen(screenName);
};

// preload screen or stack
const loadedScreenOrStackList = [];
export const preloadScreen = async screenName => {
  if (loadedScreenOrStackList.includes(screenName)) {
    return;
  }
  if (!screenComponentMap[screenName]) {
    return;
  }
  ScreenLoading.show(screenName);
  await screenComponentMap[screenName].preload();
  ScreenLoading.hide(screenName);
  loadedScreenOrStackList.push(screenName);
};
const preloadStack = async stackName => {
  if (loadedScreenOrStackList.includes(stackName)) {
    return;
  }
  const stack = rootScreenStacks.find(i => i.name === stackName);
  if (!stack) {
    return;
  }
  ScreenLoading.show(stackName);
  await Promise.all(stack.screens.map(screen => preloadScreen(screen.name)));
  ScreenLoading.hide(stackName);
  loadedScreenOrStackList.push(stackName);
};
export const preloadScreenOrStack = async name => {
  ScreenLoading.show(name);
  await Promise.all([preloadScreen(name), preloadStack(name)]);
  ScreenLoading.hide(name);
};

class Router extends React.Component {

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.backHandle);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.backHandle);
  }

  backHandle = () => {
    // // // 在根页面时按后退，提示再按一次就离开 APP，然后离开
    // if (this._tippedOneMoreBackWillLeave) {
    //   return false;
    // }
    // else {
    //   Toast(this.props.i18n.map.back_again_to_leave_app);
    //   this._tippedOneMoreBackWillLeave = true;
    //   clearTimeout(this._oneMoreBackWillLeaveTmo);
    //   this._oneMoreBackWillLeaveTmo = setTimeout(()=>{
    //     this._tippedOneMoreBackWillLeave = false;
    //   }, 2000);
    //   return true;
    // }
  }

  onUnhandledAction = action => {
    if (action?.type === "GO_BACK") {
      callNativeClose();
    }
  }

  render() {
    let timer = 0;
    return (
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          _setNavigationReady();
        }}
        onStateChange={async () => {
          // const previousRouteName = routeNameRef.current;
          const currentRoute = navigationRef?.current?.getCurrentRoute() || {};
          const rootState = navigationRef?.current?.getRootState() || {};
          // 存储供异常监控使用
          _setLastRootState(rootState);
          _setLastCurrentRoute(currentRoute);
          if (currentRoute.name) {
            const params = {};
            let now = Date.now();
            if (now - timer > 50) {
              daService.logPV({ screenName: currentRoute.name, ...params });
            }
            timer = now;
          }
        }}
        onUnhandledAction={this.onUnhandledAction}
      >
        <StackNavigator screens={rootScreens} isRoot />
      </NavigationContainer>
    );
  }
}

export default connect((state) => ({
  isReady: state.app.isReady,
}))(Router);
