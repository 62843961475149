import { request } from "../utils";
import { getCompanyId } from "@globalUtils";
import qs from "qs";

export const getList = (params = {}) => request(`/owners/${getCompanyId()}/shippingProviders?${qs.stringify(params)}`);

export const getSingle = ({ id }) => request(`/owners/${getCompanyId()}/shippingProviders/${id}`);

export const initAuthProvider = ({ shippingProviderCode, ...data }) => request(`/owners/${getCompanyId()}/shippingProvider/${shippingProviderCode}/oauth/init`, {
  method: "POST",
  data,
});

export const authProvider = ({ shippingProviderCode, ...data }) => request(`/owners/${getCompanyId()}/shippingProvider/${shippingProviderCode}/oauth/accessToken`, {
  method: "POST",
  data,
});

export const createSingle = ({ ...data }) => request(`/owners/${getCompanyId()}/shippingProviders`, {
  method: "POST",
  data,
});

export const patchSingle = ({ id, ...data }) => request(`/owners/${getCompanyId()}/shippingProviders/${id}`, {
  method: "PATCH",
  data,
});

// 获取所有配送方式列表
export const getAllShippingMethods = () => request(`/owners/${getCompanyId()}/shippingMethods`);

// 获取物流选项
export const getShippingOptions = (data) => request(`/owners/${getCompanyId()}/shippingOptions`, {
  method: "POST",
  data,
});

// 根据物流选项, 获取可用的配送方式(包含金额和配送时间)列表
export const getAvailableShippingMethods = (data = {}) => request(`/owners/${getCompanyId()}/shippingProvider/${data.shippingProviderId}/shippingMethods`, {
  method: "POST",
  data,
});

// 物流单号作废
export const voidTrackingNumber = ({ trackingNumber, orderId, shippingRecordId }) => request(`/owners/${getCompanyId()}/shippingLabels/trackingNumber/${trackingNumber}`, {
  method: "PATCH",
  data: {
    orderId,
    shippingRecordId,
    cancel: true,
  },
});

// 创建电子运单
export const createShippingLabel = data => request(`/owners/${getCompanyId()}/shippingLabels`, {
  method: "POST",
  data,
});
