let _builtAppConfig;
try {
  _builtAppConfig = require('./_appConfig.build.json');
} catch (error) {
  _builtAppConfig = {};
}

module.exports = {

  versionType: 'alpha',

  configMap: {

    get prod(){
      return {
        // Framework config ↓
        defaultEnv: 'prod',
        projectName: 'msStream',
        displayName: 'Stream',
        loginSource: 'ms_stream_h5',
        daLogTerminalId: 'STREAM_H5',
        i18nWordsFilterTags: ['mc-boss-stream'],
        i18nWordServiceApiBase: 'https://v2.microstore.app/api',
        appAssetsDirPath: './app/config/buildAssets/prod/appAssets',
        appIconPath: './app/assets/app/logo.build.png',
        apiBase: 'https://v2.microstore.app/api',
        mcApiBase: 'https://api2.dokkr.net/index.php',
        nanoApiBase: "https://dcdn-mshop-api.neyber.tech",
        // apiBase: 'http://test-micro-magasin.jiyutech.net/api',
        // mcApiBase: 'https://api2-beta.dokkr.net/index.php',
        webEntryURL: 'https://stream.microstore.app',
        // --- Custom config ↓
      };
    },

    get alpha(){
      return {
        // Framework config ↓
        defaultEnv: 'test',
        projectName: 'msStream',
        displayName: 'Stream α',
        loginSource: 'ms_stream_h5',
        daLogTerminalId: 'STREAM_H5',
        i18nWordsFilterTags: ['mc-boss-stream'],
        i18nWordServiceApiBase: 'https://v2.microstore.app/api',
        appAssetsDirPath: './app/config/buildAssets/alpha/appAssets',
        appIconPath: './app/assets/app/logo.build.png',
        apiBase: 'https://test-micro-magasin.jiyutech.net/api',
        nanoApiBase: "https://dcdn-mshop-api-test.neyber.tech/api",
        mcApiBase: 'https://api2-beta.dokkr.net/index.php',
        webEntryURL: 'https://test-ms-stream.jinjie.tech',
        // --- Custom config ↓
      };
    },
  },

  get({_forBuild = false} = {}){
    return _forBuild ? {
      ..._builtAppConfig,
      versionType: this.versionType,
      ...this.configMap[this.versionType],
    } : _builtAppConfig;
  },

};
