import { createGuid, createSharedPromise, ensureValidObject } from ".";

const stepper = (() => {
  let i = 0;
  return () => ++i;
})();

const eventListenerMap = {};

// handler: { name: string, data: object } => any
const addListener = (name, handler) => {
  if (!eventListenerMap[name]) {
    eventListenerMap[name] = [];
  }
  const id = stepper();
  const listener = {
    id,
    name,
    handler,
    remove: () => removeListener(name, id),
  };
  eventListenerMap[name].push(listener);
  return listener;
};

const removeListener = (name, id) => {
  if (eventListenerMap[name]) {
    eventListenerMap[name] = eventListenerMap[name].filter(i => i.id !== id);
  }
};

const addOnceListener = (name, handler) => {
  const listener = addListener(name, (...rest) => {
    listener.remove();
    handler(...rest);
  });
  return listener;
};

const dispatchEvent = ({ name, data }) => {
  if (eventListenerMap[name]) {
    [...eventListenerMap[name]].map(listener => {
      listener.handler({ name, data });
    });
  }
};

const commissionMap = {};
// 委托: 将event与promise结合
const setCommission = (options) => {
  options = ensureValidObject(options);
  if (
    !options.complete &&
    !options.cancel &&
    !options.callback
  ) {
    return;
  }
  const noop = () => { };
  const appEventName = createGuid();
  const commissionId = createGuid();
  const complete = options.complete || options.callback || noop;
  const cancel = options.cancel || options.callback || noop;
  addOnceListener(appEventName, ({ data }) => {
    const { type, payload } = data;
    return type === "complete" ? complete(payload) : cancel();
  });
  commissionMap[commissionId] = { appEventName };
  return commissionId;
};

const getCommission = commissionId => {
  const commission = commissionMap[commissionId];
  if (!commission) {
    return;
  }
  if (!commission.handlerMap) {
    const [promise, resolve] = createSharedPromise();
    commission.handlerMap = {
      complete: data => resolve({ type: "complete", payload: data }),
      cancel: () => resolve({ type: "cancel" }),
    };
    promise.then((action) => dispatchEvent({ name: commission.appEventName, data: action }));
  }
  return commission.handlerMap;
};

const getOrSetCommission = param => {
  return (
    (typeof param === "object" && param) ? setCommission(param) :
      typeof param === "string" ? getCommission(param) :
        undefined
  );
};

getOrSetCommission.get = getCommission;
getOrSetCommission.set = setCommission;
getOrSetCommission.autoCancel = function () {
  const commissions = Object.values(ensureValidObject(this.props?.route?.params)).map(getCommission).filter(i => i);
  if (commissions.length) {
    const _componentWillUnmount = this.componentWillUnmount;
    this.componentWillUnmount = function () {
      commissions.map(i => i?.cancel?.());
      _componentWillUnmount.call(this);
    };
  }
};

const AppEvent = {
  on: addListener,
  once: addOnceListener,
  dispatch: dispatchEvent,
  Commission: getOrSetCommission,
};

export default AppEvent;

